import React, {useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql} from "gatsby";
import StrapiComponentRender from '../components/strapiComponentRender';
import Container from '../components/container';

const Impressum = ({data}) => {
  const content = data.strapiImpressum['Inhalt'];
  const seo = data.strapiImpressum.SEO

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Container bgColor="lightbrown" isFluid={true}>
        <h1 style={{fontSize: '2.5rem', padding: '5rem 0'}}>Impressum</h1>
      </Container>
      {content.map(contentItem => (
        <StrapiComponentRender content={contentItem} />
      ))}
    </Layout>
  )
}

export default Impressum

export const pageQuery = graphql`
  query ImpressumQuery {
    strapiImpressum {
      Inhalt {
        title_textarea_one_column
        richtext_content
        backgroundcolor
      }
      SEO {
        description
        keywords
        title
      }
    }
  }
`
